html,
body {
	min-height: 100vh;
	overflow-x: hidden;
	padding: 0 !important;
}

*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

:root {
	/* colors */
	--clr-primary: #faa20cd9; /* yellow-orangey used in text */
	--clr-secondary: #fad201; /* navbar and footer color */
	--clr-yellow: var(
		--clr-primary
	); /* tente não usar, só está aqui pra não quebrar o que já foi feito */

	/* fonts */
	--font-family-primary: "Open Sans";
	--font-family-secondary: "Oswald"; /* fonte em caixa alta */
	--font-family-tertiary: "Inter";

	/* User pages */
	--font-size-title: clamp(1.5rem, 1rem + 5vw + 1vh, 3.75rem);
	--font-size-text: clamp(1.1rem, 0.5rem + 1vw + 0.5vh, 1.75rem);
	/* User pages */

	/* Adm Show Pages */
	--clr-gray: #242731;
	--h1-font-size: max(0.9rem, 1.6vw, 2rem);
	--h2-font-size: max(0.5rem, 1vw, 1.3rem);
	--p-font-size: max(0.35rem, 0.8vw, 1rem);
	/* Adm Show Pages */

	/* Create and Edit Pages */
	--create-h1-font-size: var(--h1-font-size);
	--create-h2-font-size: var(--h2-font-size);
	--create-label-font-size: clamp(1rem, 1.7vw, 2rem);
	--create-label-description-font-size: clamp(0.9rem, 1.4vw, 1.5rem);
	--create-input-font-size: clamp(0.9rem, 1.4vw, 1.7rem);
	--create-send-button-font-size: clamp(1.2rem, 2vw, 2.2rem);
	--create-add-button-font-size: clamp(0.8rem, 1.6vw, 1.8rem);
	/* Create and Edit Pages */

	/* Index and Show Project Pages */
	--index-img-size: clamp(15rem, 16vw, 30rem);
	--index-big-img-height: clamp(8rem, 35vw, 70vh);
	--index-h1-font-size: var(--h1-font-size);
	--index-h1-margin-left: clamp(2rem, 12vw, 10rem);
	--index-h2-font-size: var(--h2-font-size);
	--index-p-font-size: var(--p-font-size);
	--index-info-padding-left: clamp(1rem, 4.5vw, 10rem);
	/* Index and Show Project Pages */

	/* default website styling */
	font-family: var(--font-family-primary);
}

ul ::marker {
	content: "❖";
	color: var(--clr-secondary);
}

.root {
	height: 100%;
	display: flex;
}
